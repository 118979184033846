<template>
  <div
    class="liability-event"
    v-if="event && !liabilityEventLoading"
  >
    <div class="liability-event-header">
      <div class="header-label">
        {{ eventName }}
      </div>
      <div :class="['header-info', {'live': matchState === 'Live'}]">
        {{ event?.competition?.competitionName }} •
        <span>{{ matchState }}</span>
      </div>
      <div
        class="close-liability-event-icon"
        @click.stop="clearEvent"
      >
        <icon :name="'x'" />
      </div>
    </div>
    <div :class="['liability-event-body', {'event-disabled': isEventDisabled}]">
      <div class="liability-event-body-info">
        <div class="liability-event-body-info-group">
          <div class="liability-event-body-info-group-switch">
            <Select
              v-if="showOperatorsSelect"
              class="operator-select"
              :model-value="selectedOperator"
              :options="operatorsToSelect"
              :variant="'secondary'"
              @update:model-value="(updatedValue) => onOperatorSelect(updatedValue)"
            />
            <Toggle
              :model-value="sharpView"
              @update:model-value="value => { sharpView = value; }"
              disabled
            />
          </div>
          <div class="liability-event-body-info-group-label">
            Sharp view
          </div>
        </div>
        <div class="liability-event-body-info-group">
          <ActionInput
            class="liability-event-body-info-group-input-field"
            icon="copy"
            message="Event ID copied"
            :value="event?.eventId"
            :position="'left'"
            @click="copyEventId"
          />
        </div>
      </div>
      <div class="liability-event-body-liabilities-wrapper">
        <div class="liability-event-body-liability">
          <div class="header-label">
            BETS
          </div>
          <div class="value-label">
            {{ eventLiabilities.betsCount }}
          </div>
        </div>
        <div class="liability-event-body-liability">
          <div class="header-label">
            TURNOVER
          </div>
          <div class="value-label">
            {{ eventLiabilities.volume }}
          </div>
        </div>
        <div class="liability-event-body-liability">
          <div class="header-label">
            PROFIT/LOSS
          </div>
          <div
            :class="['liability-event-body-liability-pnl', {'down': parseFloat(eventLiabilities.pnl) < 0}]"
            v-if="eventLiabilities.pnl !== '-' && eventLiabilities.pnl !== '+0' && eventLiabilities.pnl !== '-0'"
          >
            <Icon :name="parseFloat(eventLiabilities.pnl) < 0 ? 'trend-down' : 'trend-up'" />
            {{ eventLiabilities.pnl }}
          </div>
          <div v-else>
            {{ eventLiabilities.pnl }}
          </div>
        </div>
      </div>
      <div class="liability-event-market-groups">
        <div
          :class="[
            'liability-event-market-groups-item',
            { 'liability-event-market-groups-item--is-active': !selectedMarketGroupFilter?.code },
          ]"
          @click="updateMarketGroupFilter(null)"
        >
          All
        </div>
        <div
          v-for="group in marketGroups"
          :key="group.code"
          :class="[
            'liability-event-market-groups-item',
            { 'liability-event-market-groups-item--is-active': selectedMarketGroupFilter?.code === group.code },
          ]"
          @click="updateMarketGroupFilter(group)"
        >
          {{ group.displayName }}
        </div>
      </div>
      <div class="liability-event-markets__header-search">
        <TextInput
          :model-value="searchMarketsValue"
          :placeholder="'Search markets'"
          :icon="'search'"
          @update:modelValue="onSearch"
        />
      </div>
      <div class="liability-event-markets-wrapper">
        <LiabilityEventMarkets
          v-for="(market, index) in marketDisplayConfigurations"
          :key="index"
          :market-ids="market.marketIds"
          :event-id="event.eventId"
          :is-usa-view="event.isUsaView"
          :sport-id="event.sportId"
          :sport-label="event.sport?.sportLabel"
          :competition-id="event.competitionId"
          :home-team="homeTeam"
          :away-team="awayTeam"
          :search-value="searchMarketsValue"
          :selected-market-group="selectedMarketGroupFilter || {}"
          :is-global-operator-selected="isGlobalSelected"
          :selected-operator="selectedOperator"
        />
      </div>
    </div>
    <div
      v-if="isEventDisabled"
      class="liability-event-footer"
    >
      <EventDisabledIndicator />
    </div>
  </div>
  <div
    class="liability-event"
    v-if="liabilityEventLoading"
  >
    <div class="loading-spinner-wrapper">
      <Spinner
        class="table-spinner"
      />
    </div>
  </div>
</template>

<script>
import {
  watch, onMounted, onUnmounted, computed, ref,
} from 'vue';
import { useStore } from 'vuex';
import {
  capitalize, split, cloneDeep, flatMap, toLower,
  filter, map, find, includes, isNil, orderBy,
  uniqBy,
} from 'lodash';
import { format, zonedTimeToUtc } from 'date-fns-tz';
import { useQueryParameters } from '@/composables';
import Icon from '@/components/common/Icon';
import Toggle from '@/components/common/Toggle';
import ActionInput from '@/components/common/ActionInput';
import MarketMapper from '@/services/helpers/market-mapper';
import TextInput from '@/components/common/TextInput';
import Spinner from '@/components/common/Spinner';
import Select from '@/components/common/Select';
import { getQueryModel } from './liability-table-helper';
import LiabilityEventMarkets from './LiabilityEventMarkets';
import EventDisabledIndicator from '@/components/events/EventDisabledIndicator';
import { getSuperAdminData } from '@/services/helpers/super-admin';
import { generateIdentifierByMarketParams } from '@/services/helpers/liability-updater';

const queryModel = getQueryModel();

export default {
  components: {
    Icon,
    Toggle,
    ActionInput,
    LiabilityEventMarkets,
    TextInput,
    Spinner,
    EventDisabledIndicator,
    Select,
  },
  props: {
    eventId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const { state, setParameters } = useQueryParameters(queryModel);
    const event = computed(() => store.getters['betTicker/liabilityEvent']);
    const liabilityEventLoading = computed(() => store.getters['betTicker/liabilityEventLoading']);
    const isEventDisabled = computed(() => event.value.inLimbo);

    const { isSuperAdmin, SUPER_ADMIN_CLIENT, client } = getSuperAdminData();
    const showOperatorsSelect = computed(() => isSuperAdmin && SUPER_ADMIN_CLIENT === client);

    const clientsWithBets = computed(() => filter(uniqBy([
      ...map(event.value?.eventLiabilities?.nodes, 'operatorId'),
      ...map(event.value?.marketLiabilities?.nodes, 'operatorId'),
    ], (operatorId) => operatorId)), (operatorId) => operatorId);
    const operatorsToSelect = computed(() => [
      { label: 'All clients', value: 'all-clients' },
      ...map(clientsWithBets.value, (operatorId) => ({
        label: operatorId,
        value: operatorId,
      })),
    ]);
    const selectedOperator = ref(showOperatorsSelect.value ? 'all-clients' : client);
    const isGlobalSelected = computed(() => showOperatorsSelect.value && selectedOperator.value === 'all-clients');

    const sharpView = ref(false);
    const selectedMarketGroupFilter = ref(null);
    const searchMarketsValue = ref('');

    const onSearch = (value) => {
      searchMarketsValue.value = value;
    };

    const homeTeam = computed(() => event.value?.competitors?.[0]);
    const awayTeam = computed(() => event.value?.competitors?.[1]);

    const liabilityEventMarkets = computed(() => {
      if (isGlobalSelected.value) {
        return store.getters['betTicker/globalLiabilityEventMarkets'];
      }
      return filter(store.getters['betTicker/liabilityEventMarkets'], (market) => market.operatorId === selectedOperator.value);
    });

    const displayMarketsConfiguration = computed(() => orderBy(filter(store.getters['betTicker/displayMarketsConfiguration'],
      (market) => market.active), 'position'));

    const marketGroups = computed(() => {
      let marketGroupsBySport = store.getters['betTicker/marketGroupsBySportId'](event.value?.sportId);
      // NCAA events don't have periods so we remove them from the market group list
      if (event.value?.competitionId === 'd1303850-9f46-4ef3-bc0d-11e0b8477d69') {
        marketGroupsBySport = filter(marketGroupsBySport, (marketGroup) => marketGroup.code !== 'PERIOD-BASKETBALL');
      }
      return filter(
        MarketMapper.formatMarketGroups(marketGroupsBySport, liabilityEventMarkets.value, event.value?.sport?.sportLabel),
        (market) => market.matchingMarkets || market.code === 'ALL',
      );
    });
    const updateMarketGroupFilter = (filterOption) => {
      if (filterOption === 'favorites') {
        selectedMarketGroupFilter.value = { code: 'FAVORITES' };
        return;
      }
      selectedMarketGroupFilter.value = filterOption;
    };

    const marketCodes = computed(() => {
      const groupFilters = (selectedMarketGroupFilter.value ? [selectedMarketGroupFilter.value] : [marketGroups.value]);
      const groupValues = map(groupFilters, ({ code }) => code);
      const allMarketCodes = flatMap(groupValues, (groupValue) => {
        const foundGroup = find(marketGroups.value, (marketGroup) => includes(marketGroup.code, groupValue));
        return foundGroup?.marketCodes?.nodes || [];
      });
      return map(allMarketCodes, ({ marketCode }) => marketCode);
    });

    const filteredMarkets = computed(() => filter(liabilityEventMarkets.value,
      (market) => (includes(toLower(market.marketCode), searchMarketsValue.value)
          || includes(toLower(market.marketSummary?.playerName), searchMarketsValue.value)
          || (!isNil(market.marketType.params.TEAM) && includes(toLower(homeTeam.value?.name || ''), searchMarketsValue.value))
          || (!isNil(market.marketType.params.TEAM) && includes(toLower(awayTeam.value?.name || ''), searchMarketsValue.value))
          || includes(toLower(find(displayMarketsConfiguration.value,
            (displayMarket) => displayMarket.marketCode === market.marketCode
              && displayMarket.selections === market.marketType.params.SELECTIONS)?.defaultName), searchMarketsValue.value))
          && (!selectedMarketGroupFilter.value || (selectedMarketGroupFilter.value && includes(marketCodes.value, market.marketCode)))));

    const marketDisplayConfigurations = computed(() => filter(map(displayMarketsConfiguration.value, (marketConfiguration) => ({
      ...marketConfiguration,
      marketIds: map(MarketMapper.sortSectionMarkets(filter(filteredMarkets.value, (market) => market.marketCode === marketConfiguration.marketCode
      && market.marketType.params.SELECTIONS === marketConfiguration.selections), props.isUsaView), (filterMarket) => (isGlobalSelected.value ? generateIdentifierByMarketParams(filterMarket?.marketCode, filterMarket?.marketType?.params) : filterMarket.marketId)),
    })), (marketDisplayConfiguration) => marketDisplayConfiguration.marketIds?.length));

    const dollarValue = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    const numberFormat = new Intl.NumberFormat();

    const eventName = computed(() => {
      const eventNameSplit = split(event.value?.eventName, ' v ');
      return event.value?.isUsaView
        ? `${eventNameSplit[1]} @ ${eventNameSplit[0]}`
        : `${eventNameSplit[0]} - ${eventNameSplit[1]}`;
    });

    const matchState = computed(() => {
      if (event.value?.matchState === 'FINISHED' || event.value?.matchState === 'CANCELED') return capitalize(event.value?.matchState?.toLowerCase() || '');
      if (event.value?.matchState === 'LIVE') return 'Live';
      const time = event.value?.startsAt;
      const formatDate = format(zonedTimeToUtc(time, 'UTC'), 'd MMM');
      const formatTime = format(zonedTimeToUtc(time, 'UTC'), 'H:mm');
      return `${formatDate} (${formatTime})`;
    });

    const loadEvent = (eventId) => {
      store.dispatch('betTicker/loadLiabilityEvent', {
        eventId,
        isGlobal: isGlobalSelected.value,
      });
    };

    const clearEvent = () => {
      store.dispatch('betTicker/clearLiabilityEvent');
      const query = cloneDeep(state.value);
      delete query.eventId;
      setParameters({
        ...query,
      });
    };

    const copyEventId = () => {
      if (!navigator?.clipboard) return;
      navigator.clipboard.writeText(event.value?.eventId);
    };

    const createLiabilities = (obj) => {
      if (!obj) {
        return {
          betsCount: '-',
          volume: '-',
          pnl: '-',
        };
      }
      return {
        betsCount: obj.betsCount,
        volume: dollarValue.format(obj.volume / 100),
        pnl: `${obj.pnl > 0 ? '+' : ''}${numberFormat.format(obj.pnl / 100)}`,
      };
    };

    const eventLiabilities = computed(() => {
      if (!isGlobalSelected.value) {
        const operatorLiabilities = find(event.value?.eventLiabilities?.nodes, { operatorId: selectedOperator.value });
        return createLiabilities(operatorLiabilities);
      }
      const liabilityObj = event.value?.globalEventLiabilities?.nodes?.[0];
      return createLiabilities(liabilityObj);
    });

    const onOperatorSelect = (operator) => {
      selectedOperator.value = operator;
      store.dispatch('betTicker/setLiabilityEventMarketsAndSelections', {
        eventId: props.eventId,
        isGlobal: isGlobalSelected.value,
        operatorId: selectedOperator.value,
      });
    };

    onMounted(() => {
      loadEvent(props.eventId);
    });
    onUnmounted(() => {
      clearEvent();
    });
    watch(() => props.eventId, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        selectedMarketGroupFilter.value = null;
        selectedOperator.value = showOperatorsSelect.value ? 'all-clients' : client;
        loadEvent(newValue);
      }
    });

    return {
      event,
      matchState,
      eventName,
      clearEvent,
      sharpView,
      copyEventId,
      eventLiabilities,
      updateMarketGroupFilter,
      selectedMarketGroupFilter,
      marketDisplayConfigurations,
      marketGroups,
      homeTeam,
      awayTeam,
      onSearch,
      searchMarketsValue,
      liabilityEventLoading,
      isEventDisabled,
      operatorsToSelect,
      selectedOperator,
      onOperatorSelect,
      isGlobalSelected,
      showOperatorsSelect,
    };
  },
};
</script>

<style lang="scss">
.liability-event {
  position: fixed;
  top: 0;
  right: 0;
  width: 800px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  z-index: 1201;
  border-left: 1px solid #F0F0F0;
  box-shadow: 0px 2px 4px 0px rgba(25, 20, 20, 0.08);

  .liability-event-header {
    height: 74px;
    min-height: 74px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 16px;
    border-bottom: 1px solid #F0F0F0;
    position: relative;

    .header-label {
      font-family: 'Poppins';
      font-weight: 600;
      font-size: 16px;
      color: #191414;
    }

    .header-info {
      color: #6D6D6D;
      font-size: 12px;
      &.live {
        span {
          color: #00BC57;
        }
      }
    }

    .close-liability-event-icon {
      height: 16px;
      width: 16px;
      cursor: pointer;
      position: absolute;
      right: 16px;
      top: 35%;

      .icon {
        width: 100%;
        height: 100%;

        svg {
          stroke: #191414;
        }
      }
    }
  }

  .liability-event-body {
    width: 100%;
    height: calc(100% - 74px);
    padding: 16px;
    box-sizing: border-box;

    &.event-disabled {
      height: calc(100% - 74px - 64px);
    }

    .liability-event-body-info {
      width: 100%;
      height: 32px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;

      .liability-event-body-info-group {
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 4px;

        .liability-event-body-info-group-switch {
          display: flex;
          align-items: center;
          gap: 16px;
          margin-right: 4px;

          .operator-select {
            width: 167px;
          }
        }

        .liability-event-body-info-group-input-field {
          width: 168px;
          height: 100%;

          .action-input__field {
            height: 100%;
            .text-field {
              height: 32px;
            }
          }
          .action-input__icon {
            height: 14px;
            width: 14px;
          }
        }
      }
    }

    .liability-event-body-liabilities-wrapper {
      display: flex;
      width: 100%;
      height: 62px;
      gap: 12px;
      margin-bottom: 16px;

      .liability-event-body-liability {
        height: 100%;
        width: 168px;
        padding: 8px 12px;
        display: flex;
        border-radius: 4px;
        border: 1px solid#F0F0F0;
        flex-direction: column;
        gap: 4px;

        .header-label {
          font-size: 10px;
          font-weight: 400;
          color: #6D6D6D;
        }

        .value-label {
          font-size: 18px;
          font-weight: 600;
          color: #191414;
          font-family: 'Poppins';
        }

        .liability-event-body-liability-pnl {
          border: 1px solid #00BC57;
          background-color: #E5F8EE;
          color: #004B23;
          height: 23px;
          padding: 0 8px;
          gap: 4px;
          border-radius: 12px;
          border: 1px 0px 0px 0px;
          display: flex;
          align-items: center;

          .icon {
            width: 16px;
            height: 16px;

            svg {
              path {
                fill: #004B23;
              }
            }
          }

          &.down {
            background-color: #FFEEEE;
            border-color: #FF2E2D;
            color: #661212;
            .icon {
              svg {
                path {
                  fill: #661212;
                }
              }
            }
          }
        }
      }
    }

    .liability-event-market-groups {
      display: flex;
      align-items: flex-start;
      align-self: stretch;
      user-select: none;
      overflow-x: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      position: relative;
      width: 100%;
      margin-bottom: 12px;

      &::before {
        background: #F0F0F0;
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 2px;
        content: "";
      }

      &::-webkit-scrollbar {
        display: none;
      }

      .liability-event-market-groups-item {
        display: flex;
        height: var(--Height-md, 32px);
        padding: 0px var(--padding-xsmall, 8px);
        align-items: center;
        gap: var(--Spacing-sm, 4px);
        border-bottom: 2px solid var(--neutral-stroke-default-resting, #F0F0F0);
        background: var(--neutral-bg-default-resting, #FFF);
        white-space: nowrap;
        cursor: pointer;
        position: relative;

        &--is-active {
          border-bottom: 2px solid var(--primary-stroke-weak-active, #003C3C);
        }
      }
    }

    .liability-event-markets__header-search {
      height: 33px;
      width: 100%;
      margin-bottom: 12px;

      .text-field {
        height: 33px;

        .text-field-clear {
          width: 33px;
          height: 33px;
        }
      }
    }

    .liability-event-markets-wrapper {
      height: calc(100vh - 321px);
      width: 100%;
      overflow: auto;
    }
  }

  .liability-event-footer {
    height: 64px;
    padding: 24px 16px;
    display: flex;
    align-items: center;
    border-top: 1px solid $gray400;
  }

  .loading-spinner-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
