<template>
  <div
    class="liability-market-section-wrapper"
    ref="marketRef"
  >
    <div
      :class="['liability-market-hidden-placeholder', {'has-chart': hasChart}]"
      v-show="!marketVisible"
    >
      {{ market.displayName }}
    </div>
    <div
      class="liability-market-card"
      v-if="marketVisible"
    >
      <div class="liability-market-card-header-label">
        {{ market.displayName }}
      </div>
      <div class="liability-market-liabilities-wrapper">
        <div class="liability-market-liability">
          <div class="header-label">
            BETS
          </div>
          <div class="value-label">
            {{ marketLiabilities.betsCount }}
          </div>
        </div>
        <div class="liability-market-liability">
          <div class="header-label">
            TURNOVER
          </div>
          <div class="value-label">
            {{ marketLiabilities.volume }}
          </div>
        </div>
        <div class="liability-market-liability">
          <div class="header-label">
            PROFIT/LOSS
          </div>
          <div
            :class="['liability-market-liability-pnl', {'down': parseFloat(marketLiabilities.pnl) < 0}]"
            v-if="marketLiabilities.pnl !== '-' && marketLiabilities.pnl !== '+0' && marketLiabilities.pnl !== '-0'"
          >
            <Icon :name="parseFloat(marketLiabilities.pnl) < 0 ? 'trend-down' : 'trend-up'" />
            {{ marketLiabilities.pnl }}
          </div>
          <div v-else>
            {{ marketLiabilities.pnl }}
          </div>
        </div>
        <div
          class="liability-market-navigation"
          v-if="marketIds?.length > 1"
        >
          <Icon
            :name="'chevron-left'"
            :class="{'disabled': !leftNavigationEnabled}"
            @click.stop="selectMarket('left')"
          />
          <Icon
            :name="'chevron-right'"
            :class="{'disabled': !rightNavigationEnabled}"
            @click.stop="selectMarket('right')"
          />
        </div>
        <div
          class="selections-table-toggle"
          @click.stop="() => { selectionsToggled = !selectionsToggled }"
        >
          {{ selectionsToggled ? 'Hide table' : 'Show table' }}
          <Icon :name="selectionsToggled ? 'chevron-up' : 'chevron-down'" />
        </div>
      </div>
      <div
        class="liability-market-selections"
        v-if="selectionsToggled"
      >
        <div class="liability-market-selections-header">
          <div class="liability-market-selections-column large">
            Selection
          </div>
          <div class="liability-market-selections-column small">
            No. bets
          </div>
          <div class="liability-market-selections-column">
            Turnover
          </div>
          <div class="liability-market-selections-column">
            Profit/Loss
          </div>
        </div>
        <LiabilityMarketSelection
          v-for="selection in market.selections"
          :key="selection.selectionId"
          :selection="selection"
          :market="market"
          :sport-label="sportLabel"
          :home-team="homeTeam"
          :away-team="awayTeam"
          :is-usa-view="isUsaView"
        />
      </div>
      <div
        class="liability-market-chart"
        v-if="hasChart"
      >
        <LiabilityBarChart
          :market-ids="marketIds"
          :sport-label="sportLabel"
          :home-team="homeTeam"
          :away-team="awayTeam"
          :is-usa-view="isUsaView"
          :is-global-operator-selected="isGlobalOperatorSelected"
          :selected-operator="selectedOperator"
          @selected-market-index-change="selectMarketIndex"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  ref, onMounted, onUnmounted, computed, watch,
} from 'vue';
import { useStore } from 'vuex';
import {
  each, includes, isEqual, filter,
} from 'lodash';
import Icon from '@/components/common/Icon';
import MarketMapper from '@/services/helpers/market-mapper';
import LiabilityMarketSelection from './LiabilityMarketSelection';
import LiabilityBarChart from './LiabilityBarChart';
import { generateIdentifierByMarketParams } from '@/services/helpers/liability-updater';

export default {
  components: {
    Icon,
    LiabilityMarketSelection,
    LiabilityBarChart,
  },
  props: {
    marketIds: {
      type: Array,
      required: true,
    },
    isUsaView: {
      type: Boolean,
      required: true,
    },
    sportLabel: {
      type: String,
      required: true,
    },
    homeTeam: {
      type: Object,
      required: true,
    },
    awayTeam: {
      type: Object,
      required: true,
    },
    searchValue: {
      type: String,
      required: true,
    },
    selectedMarketGroup: {
      type: Object,
      required: true,
    },
    isGlobalOperatorSelected: {
      type: Boolean,
      required: true,
    },
    selectedOperator: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const observer = ref(null);
    const marketVisible = ref(false);
    const marketRef = ref(null);
    const selectionsToggled = ref(true);
    const selectedMarketIndex = ref(0);

    const market = computed(() => {
      const marketIdentifier = props.marketIds?.[selectedMarketIndex.value];
      const storeMarket = props.isGlobalOperatorSelected
        ? store.getters['betTicker/getGlobalLiabilityMarketByIdentifier'](marketIdentifier)
        : store.getters['betTicker/getLiabilityMarketById'](marketIdentifier);
      const marketDisplayConfiguration = store.getters['betTicker/getSingleMarketDisplayConfiguration'](storeMarket.marketCode, storeMarket.marketType.params.SELECTIONS);
      const mappedMarket = MarketMapper.mapUngroupedMarket({
        marketDisplayConfiguration,
        market: storeMarket,
        homeTeam: props.homeTeam,
        awayTeam: props.awayTeam,
      });

      let selections = props.isGlobalOperatorSelected
        ? store.getters['betTicker/getGlobalLiabilityMarketSelectionsByIdentifier'](generateIdentifierByMarketParams(storeMarket?.marketCode, storeMarket?.marketType?.params))
        : store.getters['betTicker/getLiabilityMarketSelectionsById'](storeMarket.marketId);
      selections = filter(
        selections,
        (selection) => (
          props.isGlobalOperatorSelected && isEqual(selection.marketType.params, storeMarket.marketType.params))
          || !props.isGlobalOperatorSelected,
      );
      return {
        ...mappedMarket,
        selections: MarketMapper.sortSelections({ selections, isUSAView: props.isUsaView }),
      };
    });

    const dollarValue = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    const numberFormat = new Intl.NumberFormat();

    const createLiabilities = (obj) => {
      if (!obj) {
        return {
          betsCount: '-',
          volume: '-',
          pnl: '-',
        };
      }
      return {
        betsCount: obj.betsCount,
        volume: dollarValue.format(obj.volume / 100),
        pnl: `${obj.pnl > 0 ? '+' : ''}${numberFormat.format(obj.pnl / 100)}`,
      };
    };

    const marketLiabilities = computed(() => createLiabilities(market.value));

    const leftNavigationEnabled = computed(() => !!(props.marketIds?.[selectedMarketIndex.value - 1]));
    const rightNavigationEnabled = computed(() => !!(props.marketIds?.[selectedMarketIndex.value + 1]));

    const selectMarket = (side) => {
      if (side === 'left') {
        if (!props.marketIds[selectedMarketIndex.value - 1]) return;
        selectedMarketIndex.value -= 1;
        return;
      }
      if (!props.marketIds[selectedMarketIndex.value + 1]) return;
      selectedMarketIndex.value += 1;
    };

    const selectMarketIndex = (index) => {
      if (!props.marketIds[index]) return;
      selectedMarketIndex.value = index;
    };

    const chartMarkets = ['GOAL_OVER_UNDER', 'POINT_OVER_UNDER', 'RUN_OVER_UNDER', 'GOAL_HANDICAP', 'POINT_HANDICAP', 'RUN_HANDICAP'];

    const hasChart = computed(() => includes(chartMarkets, market.value?.marketCode));

    onMounted(() => {
      const options = {
        root: null,
        threshold: 0.001,
      };
      observer.value = new IntersectionObserver((entries) => {
        each(entries, (entry) => {
          marketVisible.value = entry.isIntersecting;
        });
      }, options);
      observer.value.observe(marketRef.value);
    });

    onUnmounted(() => {
      observer.value = null;
    });

    watch(() => props.searchValue, (newValue, oldValue) => {
      if (newValue !== oldValue) selectedMarketIndex.value = 0;
    });

    watch(() => props.selectedMarketGroup, (newValue, oldValue) => {
      if (newValue?.code !== oldValue?.code) selectedMarketIndex.value = 0;
    });

    watch(() => props.selectedOperator, (newValue, oldValue) => {
      if (newValue !== oldValue) selectedMarketIndex.value = 0;
    });

    return {
      marketVisible,
      marketRef,
      market,
      marketLiabilities,
      selectionsToggled,
      leftNavigationEnabled,
      rightNavigationEnabled,
      selectMarket,
      hasChart,
      selectMarketIndex,
    };
  },
};
</script>

<style lang="scss">
.liability-market-section-wrapper {
  width: 100%;
  margin-bottom: 24px;

  .liability-market-hidden-placeholder {
    height: 102px;
    width: 100%;
    font-family: "Poppins";
    font-weight: 600;
    font-size: 16px;
    color: #191414;

    &.has-chart {
      height: 462px;
    }
  }

  .liability-market-card {
    width: 100%;
    display: flex;
    flex-direction: column;

    .liability-market-card-header-label {
      font-family: 'Poppins';
      font-weight: 600;
      font-size: 16px;
      color: #191414;
      margin-bottom: 8px;
    }

    .liability-market-liabilities-wrapper {
      display: flex;
      width: 100%;
      height: 62px;
      gap: 12px;
      margin-bottom: 8px;
      position: relative;

      .liability-market-liability {
        height: 100%;
        width: 168px;
        padding: 8px 12px;
        display: flex;
        border-radius: 4px;
        border: 1px solid#F0F0F0;
        flex-direction: column;
        gap: 4px;

        .header-label {
          font-size: 10px;
          font-weight: 400;
          color: #6D6D6D;
        }

        .value-label {
          font-size: 18px;
          font-weight: 600;
          color: #191414;
          font-family: 'Poppins';
        }

        .liability-market-liability-pnl {
          border: 1px solid #00BC57;
          background-color: #E5F8EE;
          color: #004B23;
          height: 23px;
          padding: 0 8px;
          gap: 4px;
          border-radius: 12px;
          border: 1px 0px 0px 0px;
          display: flex;
          align-items: center;

          .icon {
            width: 16px;
            height: 16px;

            svg {
              path {
                fill: #004B23;
              }
            }
          }

          &.down {
            background-color: #FFEEEE;
            border-color: #FF2E2D;
            color: #661212;
            .icon {
              svg {
                path {
                  fill: #661212;
                }
              }
            }
          }
        }
      }

      .liability-market-navigation {
        height: 24px;
        padding: 0 8px;
        position: absolute;
        top: -33px;
        right: 8px;
        display: flex;
        align-items: center;

        .icon {
          cursor: pointer;
          width: 18px;
          height: 18px;
          margin-left: 4px;
          svg {
            stroke: #191414;
            path {
              stroke-width: 2;
            }
          }

          &.disabled {
            cursor: default;
            svg {
              path {
                stroke-width: 1;
              }
            }
          }
        }
      }

      .selections-table-toggle {
        height: 24px;
        padding: 0 8px;
        position: absolute;
        bottom: 0;
        right: 8px;
        display: flex;
        cursor: pointer;
        align-items: center;
        user-select: none;

        .icon {
          width: 16px;
          height: 16px;
          margin-left: 4px;
          svg {
            stroke: #191414;
            path {
              stroke-width: 1;
            }
          }
        }
      }
    }

    .liability-market-selections {
      width: 100%;
      .liability-market-selections-header {
        width: 100%;
        height: 32px;
        background-color: #FAFAFA;
        display: flex;
        color: #6D6D6D;

        .liability-market-selections-column {
          width: 27%;
          min-width: 27%;
          display: flex;
          align-items: center;
          height: 100%;
          padding: 0 8px;
          user-select: none;

          &.large {
            width: 28%;
            min-width: 28%;
          }
          &.small {
            width: 18%;
            min-width: 18%;
          }
        }
      }
    }

    .liability-market-chart {
      margin-top: 24px;
      width: 100%;
    }
  }
}
</style>
